'use client';

import { createGlobalStyle } from 'styled-components';

import { mq640px } from '@/uiPrimitives/base/mediaQuery.definitions.css';
import {
	pageMaxInlineSize,
	gridColumnGap,
} from '@/uiPrimitives/layout/pageGrid/gridVars.css';

export const GlobalStyles = createGlobalStyle`
    .legacy {
        --max-content-width: ${pageMaxInlineSize};

        /* Fallback for Chrome 106 and older
         * https://caniuse.com/viewport-unit-variants
         */
        --layout-side-space: max(${gridColumnGap}, (100vw - ${pageMaxInlineSize}) / 2 + ${gridColumnGap});
        @supports (inline-size: 100dvi) {
            --layout-side-space: max(${gridColumnGap}, (100dvi - ${pageMaxInlineSize}) / 2 + ${gridColumnGap});
        }
        --grid-item-margin-inline: 1rem;
        @media ${mq640px.max} {
            --grid-item-margin-inline: var(--layout-side-space);
        }
    }
    /* To style terms in different language in CMS content */
    :where(.legacy) span[lang] {
        font-style: italic;
    }
`;
